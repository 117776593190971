import styled from '@emotion/styled';
import React from 'react';
import { device } from './layout/GlobalStyles';
import { PanelContainer } from './layout/Panel-Container';
import { StaticImage } from 'gatsby-plugin-image';

const StyledHomeMainPanels = styled.section`
    padding: 40px 0px 72px;
    .text-container {
        padding: 32px 0px;
    }
    .image {
        border-radius: 15px;
        img {
            border-radius: 15px;
        }
    }
    @media ${device.laptop} {
        padding: 72px 0px;
        .text-container {
            width: 488px;
        }
        .image {
            width: 511px;
            img {
                width: 511px;
            }
        }
        .divider {
            margin-top: 72px;
        }
    }
`;

export const HomeMainPanels: React.FC = () => {
    return (
        <StyledHomeMainPanels>
            <PanelContainer>
                <div className="text-container">
                    <h2>Welcome to Innovative Medicine</h2>
                    <p>
                        we are glad you are here! You can count on our team of highly skilled,
                        caring, and compassionate healthcare providers to work with you to realize
                        your goals of better health physically, mentally, and spiritually. We offer
                        treatment for many conditions and are as invested in your well-being as you
                        are. Call us today, and let’s get started!
                    </p>
                </div>
                <StaticImage
                    className="image"
                    src="../images/man-cheering.jpeg"
                    alt="runner celebrating victory"
                    quality={100}
                    placeholder="none"
                />
            </PanelContainer>
            <div className="divider"></div>
            <PanelContainer reverse>
                <div className="bottom-text text-container">
                    <h2>What sets us apart at Innovative Medicine? </h2>
                    <p>
                        We care deeply about our patients and are always searching for the newest
                        and best innovative treatments to serve your health and well-being. By
                        thinking beyond the drop-down menus many medical providers have become bound
                        by, we can help patients restore health and balance to their lives. Call us
                        today and see how we can help you.
                    </p>
                </div>
                <StaticImage
                    className="image"
                    src="../images/holding-hands.jpeg"
                    alt="doctor holding a patients hand"
                    quality={100}
                    placeholder="none"
                />
            </PanelContainer>
        </StyledHomeMainPanels>
    );
};

import { DefaultLayout } from '../layouts/DefaultLayout';
import { HomeHero } from '../components/HomeHero';
import { HomePicturePanel } from '../components/Home-Picture-Panel';
import { HomeMainPanels } from '../components/Home-Main-Panels';
import { ServicesOffering } from '../components/Home-Services-Offering';
import { MeetOurExpertTeam } from '../components/Meet-Our-Expert-team';
import { HomeReviews } from '../components/Home-Reviews';
import { Seo } from '../components/seo';
const Home: Page = () => {
    return (
        <>
            <Seo
                title="Natural and traditional Medical Care Service in Lafayette, IN - Innovative Medicine"
                description="Innovative Medicine medical team provides Addiction Treatment, Detox Treatment, Diabetes Treatment, Cancer Prevention Treatment and more in Lafayette IN."
            />
            <HomeHero />
            <HomePicturePanel />
            <HomeMainPanels />
            <ServicesOffering />
            <MeetOurExpertTeam />
            <HomeReviews />
        </>
    );
};
Home.Layout = DefaultLayout;

export default Home;

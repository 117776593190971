import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const MainContainer = styled.div`
    background: #fcfcfc;
    /* Gray/gray3 */
    border: 1px solid #c0cbcf;
    border-radius: 15px;
    .top-panel {
        div,
        img {
            border-radius: 15px 15px 0px 0px;
        }
        border-radius: 15px 15px 0px 0px;
    }
    @media ${device.tablet} {
        display: flex;
        justify-content: center;

        img {
            z-index: 0;
            height: 250px;
            width: 100%;
        }
        .top-panel {
            div,
            img {
                border-radius: 15px 0px 0px 0px;
            }
            border-radius: 0;
        }
        .bottom-panel {
            div,
            img {
                border-radius: 0px 15px 0px 0px;
            }
            border-radius: 0;
        }
    }
    @media ${device.laptop} {
        margin-top: 62px;
        min-width: 1100px;
        border-radius: 15px;
        .top-panel {
            div,
            img {
                border-radius: 15px 0px 0px 15px;
            }
            border-radius: 0;
        }
        .bottom-panel {
            div,
            img {
                border-radius: 0px 0px 0 0px;
            }
            border-radius: 0;
        }
    }
    @media ${device.laptopL} {
        margin-top: 62px;
        min-width: 1200px;
        border-radius: 15px;
        .top-panel {
            div,
            img {
                border-radius: 15px 0px 0px 15px;
            }
            border-radius: 0;
        }
        .bottom-panel {
            div,
            img {
                border-radius: 0px 0px 0 0px;
            }
            border-radius: 0;
        }
    }
`;

const Panel = styled.div`
    .text-container {
        padding: 25px 15px;
    }
    .title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
    }
    p:last-of-type {
        color: ${colors.gray4};
        margin: 0;
        font-size: 14px;
    }
    span {
        font-weight: 400;
        font-size: 18px;
        line-height: 161%;
        color: ${colors.blue2};
        text-decoration: underline;
    }
    @media ${device.tablet} {
        width: 240px;

        .title {
            height: 50px;
        }
    }
    @media ${device.laptop} {
        width: 400px;
        height: 172px;
        display: flex;
        img {
            min-width: 124px;
        }
        p:last-of-type {
            margin-bottom: 8px;
        }
        .text-container {
            min-width: 196px;
        }
        .title {
            height: auto;
            margin-bottom: 14px;
        }
    }
`;

export const HomePicturePanel: React.FC = () => {
    return (
        <MainContainer className="shadow container">
            <Panel className="top-panel">
                <StaticImage
                    src="../images/overcome-your-addiction.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="man raising his fist to the sky"
                />
                <div className="text-container">
                    <p className="title">Get More Energy!</p>
                    <p>Replace your old habits with a new life.</p>
                    <Link to="/services/treatments/energy-get-more-of-it/">
                        <span>Explore More</span>
                    </Link>
                </div>
            </Panel>

            <Panel>
                <StaticImage
                    src="../images/weight-loss-that-works.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="woman with ruler around waist"
                />
                <div className="text-container">
                    <p className="title">Weight Loss That Works</p>
                    <p>We have three weight loss options.</p>
                    <Link to="/services/treatments/weight-loss/">
                        <span>Explore More</span>
                    </Link>
                </div>
            </Panel>
            <Panel className="bottom-panel">
                <StaticImage
                    src="../images/low-sex-drive.jpeg"
                    placeholder="none"
                    quality={100}
                    alt="couple making a heart with their hands"
                />
                <div className="text-container">
                    <p className="title">Low Sex Drive?</p>
                    <p>See how testosterone infections can help you.</p>
                    <Link to="/services/treatments/testosterone/">
                        <span>Explore More</span>
                    </Link>
                </div>
            </Panel>
        </MainContainer>
    );
};

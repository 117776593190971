import React from 'react';
import styled from '@emotion/styled';
import { Button } from './layout/StyledComponents';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { ServicePanel } from './layout/Service-Panel';
import { device, colors } from './layout/GlobalStyles';

const StyledServicesOffering = styled.div`
    .service-panels-container {
        > div {
            margin: 32px 0px;
        }
    }
    > button {
        width: 340px;
        margin: 32px auto 0px;
    }

    @media ${device.tablet} {
        .service-panels-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            > div {
                margin: 16px 0;
            }
        }
    }
    @media ${device.laptop} {
        .wide {
            width: 520px;
        }
    }
    @media ${device.laptopL} {
        .wide {
            width: 590px;
        }
    }
`;

export const ServicesOffering: React.FC = () => {
    return (
        <StyledServicesOffering className="container">
            <h2>{`Services we're offering`}</h2>
            <div className="service-panels-container">
                <ServicePanel
                    url="/services/treatments/weight-loss/"
                    classType="wide"
                    service="Weight Loss"
                >
                    <StaticImage
                        placeholder="none"
                        quality={100}
                        src="../images/weight-loss.jpeg"
                        alt="woman holding ruler to waist"
                    />
                </ServicePanel>
                <ServicePanel
                    url="/services/treatments/female-hormones/"
                    classType="wide"
                    service="Female Hormones"
                >
                    <StaticImage
                        placeholder="none"
                        quality={100}
                        src="../images/female-hormones.jpeg"
                        alt="woman stressed"
                    />
                </ServicePanel>
                <ServicePanel url="/services/treatments/testosterone/" service="Testosterone">
                    <StaticImage
                        placeholder="none"
                        quality={100}
                        src="../images/testosterone.jpeg"
                        alt="man stressed"
                    />
                </ServicePanel>
                <ServicePanel url="/therapies/alcoholism/" service="Alcoholism">
                    <StaticImage
                        placeholder="none"
                        quality={100}
                        src="../images/fatigue.jpeg"
                        alt="man stressed in office"
                    />
                </ServicePanel>
                <ServicePanel url="/pain/" service="Pain">
                    <StaticImage
                        placeholder="none"
                        quality={100}
                        src="../images/pain.jpeg"
                        alt="man showing doctor location of pain"
                    />
                </ServicePanel>
            </div>
            <Button button="secondary">
                <Link
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: `${colors.blue2}`,
                    }}
                    to="/our-services/"
                >
                    Explore More Services
                </Link>
            </Button>
        </StyledServicesOffering>
    );
};

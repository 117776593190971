import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import mobileWhite from '../images/svgs/phone-white.svg';
import mapMarker from '../images/svgs/marker.svg';
import { Button } from './layout/StyledComponents';
import { details } from '../details';
import facebook from '../images/svgs/facebook.svg';
import twitter from '../images/svgs/twitter.svg';
import linkedin from '../images/svgs/linkedin.svg';
import { Link } from 'gatsby';

const StyledHomeHero = styled.div`
    position: relative;
    height: 100vh;

    @media ${device.laptop} {
        .mobile-button {
            display: none;
        }
        margin-bottom: -40px;
    }
    @media ${device.laptopL} {
        margin-bottom: -60px;
    }
    @media ${device.desktop} {
        margin-bottom: -150px;
    }
`;

const GradientContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @keyframes gradientAnimation {
        0% {
            transform: rotate(0deg) scale(1.2);
        }
        50% {
            transform: rotate(360deg) scale(0.8) translateX(-30deg);
        }
        100% {
            transform: rotate(720deg) scale(1.2);
        }
    }
    .gradient-image {
        z-index: -1;
        left: -285px;
        animation: gradientAnimation 60s infinite;
        position: absolute;
        top: 00px;
        width: 980px;
    }
    @media ${device.tablet} {
        .gradient-image {
            z-index: -1;
            animation: gradientAnimation 60s infinite;
            position: absolute;
            top: 10%;
            left: 0%;
            width: 780px;
        }
    }
    @media ${device.laptop} {
        .gradient-image {
            z-index: -1;
            animation: gradientAnimation 60s infinite;
            position: static;
            width: 780px;
        }
    }
    @media ${device.laptopL} {
        .gradient-image {
            z-index: -1;
            animation: gradientAnimation 60s infinite;
            position: static;
            width: 780px;
            margin-top: -40px;
        }
    }
    @media ${device.desktop} {
        .gradient-image {
            margin-top: -100px;
            width: 980px;
        }
    }
`;

const ContentWrapper = styled.div`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 120px;
    .location-container {
        display: flex;
        img {
            margin-right: 15px;
        }
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 161%;
            text-decoration: underline;
            color: ${colors.gray5};
        }
    }
    h1 {
        width: 255px;
        font-weight: 400;
        text-align: center;
        color: ${colors.white};
        span {
            font-weight: 700;
        }
    }
    button {
        margin-top: 16px;
    }
    @media ${device.tablet} {
        margin-top: 250px;
        h1 {
            width: 455px;
        }
    }
    @media ${device.laptop} {
        margin-top: 200px;
        position: relative;
        bottom: 800px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    @media ${device.laptopL} {
        margin-top: 200px;
        position: relative;
        bottom: 800px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    @media ${device.desktop} {
        margin-top: 130px;
        transition: 0.2s ease-in;
        h1 {
            font-size: 52px;
            width: 550px;
        }
    }
`;

const Social = styled.div`
    display: flex;
    margin-top: 70px;
    align-items: center;
    justify-content: space-evenly;
    div {
        width: 50px;
        height: 1px;
        background: ${colors.black};
    }
    @media ${device.tablet} {
        margin-top: 200px;
    }
    @media ${device.laptop} {
        flex-direction: column;
        justify-content: space-evenly;
        height: 560px;
        width: 24px;
        position: absolute;
        right: 106px;
        top: -80px;
        align-items: center;
        div {
            transform: rotate(90deg);
            width: 100px;
            height: 1px;
            background: ${colors.black};
        }
        img {
            width: 100%;
        }
    }
    @media ${device.laptopL} {
        top: -140px;
    }
    @media ${device.desktop} {
        top: -120px;
    }
`;

export const HomeHero: React.FC = () => {
    return (
        <StyledHomeHero>
            <GradientContainer>
                <StaticImage
                    className="gradient-image"
                    placeholder="none"
                    quality={100}
                    src="../images/gradient.png"
                    alt="blue gradient pattern"
                />
            </GradientContainer>
            <ContentWrapper className="container">
                <h1>
                    Innovative Medicine: <span>Unlock</span> a <span>better</span> You
                </h1>
                <a
                    rel="noopener norefferer noreferrer"
                    target="__blank"
                    href={`${details.addressUrl}`}
                >
                    <div className="location-container">
                        <img
                            src={mapMarker}
                            alt="3554 Promenade Pkwy, Suite H, Lafayette, IN 47909"
                        />
                        <p>3554 Promenade Pkwy, Suite H, Lafayette, IN 47909</p>
                    </div>
                </a>
                <a target="__blank" rel="noopener noreferrer" href={`${details.phoneUrl}`}>
                    <Button fill button="primary">
                        <img src={mobileWhite} alt="Call us at (765) 471-1100" />
                        <span>Call us at (765) 471-1100</span>
                    </Button>
                    <Link className="mobile-button" to="#contact">
                        <Button fill button="primary">
                            Request Information
                        </Button>
                    </Link>
                </a>
            </ContentWrapper>
            <Social>
                <div></div>
                <a target="__blank" rel="noopener noreferrer" href={`${details.facebookUrl}`}>
                    <img style={{ width: '16px' }} src={facebook} alt="facebook logo" />
                </a>
                <a target="__blank" rel="noopener noreferrer" href={`${details.twitterUrl}`}>
                    <img src={twitter} alt="twitter logo" />
                </a>
                <a target="__blank" rel="noopener noreferrer" href={`${details.linkedinUrl}`}>
                    <img src={linkedin} alt="linkedin logo" />
                </a>
                <div></div>
            </Social>
        </StyledHomeHero>
    );
};

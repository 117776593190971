import React from 'react';
import styled from '@emotion/styled';
// import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import { device } from './layout/GlobalStyles';
import { Link } from 'gatsby';

const StyledMeetOurExpertTeam = styled.div`
    text-align: center;
    padding: 72px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
        margin-top: 32px;
        border-radius: 15px;
        width: 343px;
        height: 190px;
        img {
            height: 190px;
            border-radius: 15px;
        }
    }
    button {
        margin-top: 42px;
    }
    @media ${device.tablet} {
        padding: 36px 0px 72px;
        p {
            max-width: 530px;
        }
        > div {
            margin-top: 32px;
            border-radius: 15px;
            width: 100%;
            height: 380px;
            img {
                width: 100%;
                height: 380px;
                border-radius: 15px;
            }
        }
    }
    @media ${device.laptop} {
        > div {
            height: 480px;
            width: 100%;
            img {
                height: 480px;
            }
        }
        h2 {
            margin: 32px 0px 0px;
        }
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 161.5%;
            /* or 29px */
            max-width: 900px;
        }
    }
`;

export const MeetOurExpertTeam: React.FC = () => {
    return (
        <StyledMeetOurExpertTeam className="container">
            <h2>Meet our expert team</h2>
            <p>
                Our staff members are caring and are trained to assist in delivering the highest
                quality of service. They work hard to assure individual attention and an enjoyable
                office visit.
            </p>
            <StaticImage
                className="image-shadow"
                src="../images/team-group-shot.jpeg"
                alt="group shot of Innovative Medicine team"
                placeholder="none"
                quality={100}
            />
            <Link to="/about-us/meet-our-team/">
                <Button button="secondary">Get To Know More About Our Team</Button>
            </Link>
        </StyledMeetOurExpertTeam>
    );
};

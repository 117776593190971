import styled from '@emotion/styled';
// import { Link } from 'gatsby';
// import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './layout/GlobalStyles';
import star from '../images/svgs/star.svg';
import { Button } from './layout/StyledComponents';
import { Link } from 'gatsby';

const StyledHomeReviews = styled.div`
    button {
        margin: 32px auto;
    }
    .panel-container {
        display: flex;
        flex-wrap: none;
        margin: 32px 0 0 0;
        overflow-x: scroll;
        > div {
            margin-right: 16px;
            height: 250px;
        }
    }
    .big-panel {
        min-height: 436px;
    }
    @media ${device.laptop} {
        max-width: 1080px;
        margin: 0 auto 228px;
        .big-panel {
            width: 400px;

            padding-bottom: 20px;
        }
        .panel-container {
            position: relative;
            display: flex;
            display: block;
            overflow-x: visible;
            margin: 32px 0 0 0;
            > div {
                height: auto;
                margin-right: 0px;
            }
            .panel-1 {
                position: absolute;
                top: 0px;
                right: 320px;
            }
            .panel-2 {
                position: absolute;
                top: 72px;
                right: 00px;
            }
            .panel-3 {
                position: absolute;
                top: 408px;
                right: 320px;
            }
            .panel-4 {
                position: absolute;
                top: 488px;
                right: 00px;
            }
        }
        button {
            margin: 128px 0px 0px 60px;
        }
    }
    @media ${device.laptopL} {
        max-width: 1200px;

        .big-panel {
            padding: 15px 32px;
            width: 490px;
        }
        .panel-container {
            .panel-1 {
                position: absolute;
                top: 0px;
                right: 325px;
            }
            .panel-2 {
                position: absolute;
                top: 102px;
                right: 00px;
            }
            .panel-3 {
                position: absolute;
                top: 408px;
                right: 325px;
            }
            .panel-4 {
                position: absolute;
                top: 508px;
                right: 00px;
            }
        }
        button {
            margin: 82px 0px 0px 130px;
        }
    }
`;

const ReviewPanel = styled.div`
    background: #e1eff5;
    border-radius: 15px;
    padding: 5px 20px;
    position: relative;
    margin: 32px 0px 0px 25px;
    min-width: 300px;
    padding-bottom: 30px;
    h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 132.5%;
        text-transform: capitalize;
        color: ${colors.blue3};
    }
    .star-container {
        right: 10px;
        bottom: 15px;
        position: absolute;
        img {
            margin-right: 5px;
        }
    }
    @media ${device.laptop} {
        padding-bottom: 40px;
        width: 216px;
        margin: 0px 0px 0px 0px;
        min-width: 256px;
    }
`;

export const HomeReviews: React.FC = () => {
    return (
        <StyledHomeReviews>
            <h2 className="container">What our patients say</h2>
            <div className="panel-container">
                <ReviewPanel className="big-panel">
                    <h2>Crystal McAninch</h2>
                    <p>
                        I have struggled with addiction for almost 15 years. I have for many years
                        never reached out for help because I was ashamed. I called Innovative
                        Medicine on Thursday April 7th and spoke with Brandy (who was very kind and
                        helpful!) She was able to get me an appointment that Monday April 11th!
                        After reading the reviews and developing my own opinion of this company, I
                        am so excited to meet each and everyone of them and get my life back! Thank
                        you for all you do!!
                    </p>
                    <div className="star-container">
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                    </div>
                </ReviewPanel>
                <ReviewPanel className="panel-1">
                    <h2>Dawn Sale</h2>
                    <p>
                        {`Dr. Turner changed my life! I have Hashimoto's, an autoimmune disorder. He
                        listened to how I was feeling and adjusted my meds until I felt good. He
                        also addressed several other health issues....`}
                    </p>
                    <div className="star-container">
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                    </div>
                </ReviewPanel>
                <ReviewPanel className="panel-2">
                    <h2>James Oswalt</h2>
                    <p>
                        Been a Patient for Years and never had an issue. Everyone that works there
                        is super friendly. The Doc Turner and his team of other Doctors are great
                        and spend time listening. They think ...
                    </p>
                    <div className="star-container">
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                    </div>
                </ReviewPanel>
                <ReviewPanel className="panel-3">
                    <h2>Jane Marie Kroegher</h2>
                    <p>
                        {`I am so grateful for the excellent care I receive from Dr. Turner and his
                        staff! I have been a patient for over a decade and have had exception
                        results from the "outside the box" approach...`}
                    </p>
                    <div className="star-container">
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                    </div>
                </ReviewPanel>
                <ReviewPanel className="panel-4">
                    <h2>Dustin Dishon</h2>
                    <p>
                        {`Had my first appointment and was beyond impressed! I went for Testosterone
                        therapy. They don't rush you through your appointment, and they go over
                        every aspect of your health to...`}
                    </p>
                    <div className="star-container">
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                        <img src={star} alt="gold star" />
                    </div>
                </ReviewPanel>
            </div>
            <Link to="/about-us/testimonials/">
                <Button button="secondary">Read More Reviews</Button>
            </Link>
        </StyledHomeReviews>
    );
};
